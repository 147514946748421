<template>
	<div>
		<van-steps :active="step_active" active-icon="success" active-color="#ff976a">
			<van-step>{{translate('withdraw_option')}}</van-step>
			<van-step>{{translate('rsswithdrawal_amount')}}</van-step>
			<van-step>{{translate('accounts_receivable')}}</van-step>
			<van-step>{{translate('moderated')}}</van-step>
		</van-steps>

		<h4 class="h4_title">{{translate('account_balance')}}</h4>
		
		<van-cell-group>
			<van-cell title="账户余额" :value="balance_show" />
		</van-cell-group>

		<van-cell-group>
			<van-cell :title="translate('amount_that_can_be_withdrawn')" :value="available_show" />
		</van-cell-group>

		<h4 class="h4_title">{{translate('amount_rsswithdrawal_amount')}}</h4>

		<van-field
			clearable
			type="number"
			v-model="formItem.total"
			name="total"
			:label="translate('amount_rsswithdrawal_amount')"
			:placeholder="translate('amount_rsswithdrawal_amount')"
			:rules="[{ required: true, trigger: 'onChange', message: translate('fill_in_the_rsswithdrawal_amount') }]"
		/>

		<h4 class="h4_title">支付密码</h4>

		<van-field
			clearable
			type="password"
			v-model="formItem.password"
			name="password"
			label="支付密码"
			placeholder="支付密码"
		/>

		<div class="common_block">
			<van-button type="warning" block @click="next">{{translate('amount_next_step')}}</van-button>
		</div>

		<div class="common_block">
			<p style="text-align: right;color: blue;" @click="goHistory">{{translate('rsswithdrawal_record')}}</p>
		</div>
	</div>
</template>

<script>
import {SHA1} from '../../utils/sha1.js';
	export default {
		name: 'rsswithdraw_amount',
		data() {
			return {
				step_active: 1,

				formItem: {
					c: 0,
					total: "",
					token: "",
					bank_type: "",
					bank_info: "",
					password: ""
				},
				
				symbol: "",
				balance_show: "",
				balance: 0,
				available_show: "",
			}
		},
		mounted() {
			if (this.$route.query.token == "" || this.$route.query.token == undefined) {
				this.$router.replace('/nologin')
			} else {
				if (window.sessionStorage.getItem('c') == undefined) {
					this.$router.replace('/rsswithdraw/index?token=' + this.$route.query.token)
				}

				this.formItem.token = this.$route.query.token
				this.formItem.c = window.sessionStorage.getItem('c')

				if (window.sessionStorage.getItem('total')) {
					this.formItem.total = window.sessionStorage.getItem('total')
				}

				this.init()
			}
		},
		methods: {
			init() {
				this.$axios({
					method: 'post',
					url: 'rss_withdraw/index', 
					data: {
						token: this.formItem.token,
						c: this.formItem.c
					}
				}).then ((res) => {
					if (res.success) {
						this.withdraw_tip = res.data.withdraw_tip
						
						this.symbol = res.data.symbol
						this.balance = parseFloat(res.data.available)
						this.balance_show = res.data.balance_show
						this.available_show = res.data.available_show

						window.sessionStorage.setItem('balance', this.balance)
					}
				})
			},
			next() {

				if (this.formItem.total == '') {
					this.$dialog.alert({
						message: this.translate('fill_in_the_rsswithdrawal_amount')
					})
					
					return false
				}
				if (this.formItem.password == '') {
					this.$dialog.alert({
						message: '请输入支付密码'
					})
					
					return false
				}
				var total = parseFloat(this.formItem.total)
		
				if (total <= 0) {
					this.$dialog.alert({
						message: this.translate('fill_in_the_rsswithdrawal_amount')
					})
					
					return false
				}
				
				if (this.formItem.c == 3 && total < 50) {
					this.$dialog.alert({
						message: this.translate('minimum_usdt_rsswithdrawal_is')
					})
					
					return false
				}

				if (total > this.balance) {
					this.$dialog.alert({
						message: this.translate('insufficient_account_balance') + this.available_show
					})
					
					return false
				}

				window.sessionStorage.setItem('total', total)
				window.sessionStorage.setItem('symbol', this.symbol)
				let mps= SHA1(this.formItem.password)
				this.$axios({
					method: 'post',
					url: 'rss_withdraw/methods', 
					data: {
						token: this.formItem.token,
						currency_id: this.formItem.c,
						pay_password: mps
					},
				}).then ((res) => {
					if (res.success) {
						this.$router.push('/rsswithdraw/type?token=' + this.formItem.token+'&mps='+mps)
					} else {
						this.$dialog.alert({
							message: res.error_text
						})
					}
				})
				
			},
			goHistory() {
				this.$router.push('/rsswithdraw/history?token=' + this.formItem.token)
			}
		},
	}
</script>
